import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaYoutube, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const socialButtons = [
    { icon: <FaInstagram />, color: "text-pink-500", url: "https://www.instagram.com/ai.verbalyze/" },
    { icon: <FaLinkedin />, color: "text-blue-600", url: "https://www.linkedin.com/company/verbalyze00" },
    { icon: <FaXTwitter />, color: "text-black", url: "https://x.com/aiverbalyze" },
    { icon: <FaYoutube />, color: "text-red-500", url: "https://www.youtube.com/@aiverbalyze" },
  ];

  useEffect(() => {
    const adjustFooter = () => {
      const footer = document.querySelector('footer');
      const body = document.body;
      const html = document.documentElement;

      const height = Math.max(body.scrollHeight, body.offsetHeight, 
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      if (height <= window.innerHeight) {
        footer.style.position = 'fixed';
        footer.style.bottom = '0';
        footer.style.left = '0';
        footer.style.right = '0';
      } else {
        footer.style.position = 'static';
      }
    };

    window.addEventListener('resize', adjustFooter);
    adjustFooter(); // Call once on mount

    return () => window.removeEventListener('resize', adjustFooter);
  }, []);

  return (
    <footer className="bg-[rgb(11,88,181)] pt-12 pb-3 text-center w-full">
     <div className="grid grid-cols-1  md:grid-cols-4 md:text-left ">
        <div className="mr-4 pl-4 flex justify-center mb-12">
        <div>
              <a href="/">
                <img
                  src="/whitelogo.png"
                  alt="Verbalyze"
                  className="w-72 h-auto"
                />
              </a>
          {/* Social Icons */}
          <div className="ml-4">
          <div className="mt-6 lg:mb-0 mb-6 flex justify-center ">
            {socialButtons.map((button, index) => (
              <a
                key={index}
                href={button.url}
                target="_blank"
                rel="noreferrer" 
                className={`bg-white ${button.color} shadow-lg h-10 w-10 md:h-8 md:w-8 lg:h-12 lg:w-12 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 hover:scale-110 transition-transform duration-200`}
              >
                {button.icon}
              </a>
            ))}
          </div>
          </div>
          </div>
        </div>
        {/* Links Grid */}
        <div className="flex justify-center sm:mb-12 md:mb-0" >
            {/* Company Links */}
              <ul className="list-none">
                {[
                  { name: "About us", path: "/about-us" },
                  { name: "Partner With Us", path: "/partner" },
                  { name: "Careers", path: "/careers" },
                  { name: "Newroom", path: "/newsroom" },
                  { name: "Pricing", path: "/pricing" },
                  { name: "Blog", path: "/blog" },
                  { name: "Contact Us", path: "/contact-us" },
                ].map((link, index) => (
                  <li key={index}>
                    <Link
                      to={link.path}
                      className="text-slate-100   hover:text-slate-800 font-semibold hover:no-underline block pb-2 text-xl"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
        </div>
        <div className="flex justify-center sm:mb-12 md:mb-0">
            <ul className="list-none">
              {[
                { name: "BFSI", path: "/industry/bfsi" },
                { name: "Healthcare", path: "/industry/healthcare" },
                { name: "Utilities", path: "/industry/utilities" },
                { name: "Retail", path: "/industry/retail" },
                { name: "Other Industries", path: "/industry/other" },
              ].map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className="text-slate-100 mx-auto hover:text-slate-800 hover:no-underline font-semibold block pb-2 text-xl"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
        </div>
        {/* Resources Links */}
        <div className="flex justify-center">
            <ul className="list-none">
              {[
                { name: "Terms of Service", path: "/terms-of-service" },
                { name: "Data Security Policy", path: "/data-security-policy" },
                { name: "Privacy Policy", path: "/privacy-policy" },
                { name: "Sitemap", path: "/sitemap" },
                { name: "Cookie Preferences", path: "/cookie-preferences" },
              ].map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className="text-slate-100 hover:text-slate-800 hover:no-underline font-semibold block pb-2 text-xl"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
        </div>
        {/* Resources Links */}
      </div>


      <div className="w-full flex justify-center px-4 mx-auto">
        <div className="text-sm text-stone-100 font-semibold py-1">
          2025 Aiverbalyze Technologies Pvt. Ltd. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

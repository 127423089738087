import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, IconButton, Button } from '@mui/material';
import './playground.css';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AnimatedWaves from './animatedwaves';
import axios from 'axios';

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

// Initialize IndexedDB
const dbName = 'AudioDB';
const storeName = 'audioFiles';
let db;

const initDB = () => {
  const request = indexedDB.open(dbName, 1);
  
  request.onerror = (event) => {
    console.error("IndexedDB error:", event.target.error);
  };

  request.onsuccess = (event) => {
    db = event.target.result;
  };

  request.onupgradeneeded = (event) => {
    db = event.target.result;
    db.createObjectStore(storeName, { keyPath: "id" });
  };
};

initDB();

// Remove the API_URL constant and the custom axios instance

const languageCodes = {
  'Telugu': 'tel', 'Hindi': 'hin', 'Tamil': 'tam', 'Kannada': 'kan', 'Malayalam': 'mal',
  'Marathi': 'mar', 'Bengali': 'ben', 'Gujarati': 'guj', 'Odia': 'ory', 'Punjabi': 'pan',
  'Assamese': 'asm', 'English': 'eng', 'Spanish': 'spa', 'French': 'fra', 'German': 'deu',
  'Italian': 'ita', 'Portuguese': 'por', 'Dutch': 'nld', 'Russian': 'rus', 'Japanese': 'jpn',
  'Korean': 'kor', 'Chinese': 'cmn', 'Arabic': 'arb'
};

const Playground = () => {

  const playAudio = (audioBuffer) => {
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();
  };
  const [isSTTActive, setIsSTTActive] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [aiResponse, setAiResponse] = useState(null);
  const [userAudio, setUserAudio] = useState(null);
  const [userAudioBuffer, setUserAudioBuffer] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [localFile, setLocalFile] = useState(null);
  
  useEffect(() => {
    console.log('Selected language changed:', selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    console.log('Playground component mounted');
    console.log('Available languages:', Object.keys(languageCodes));
    return () => {
      console.log('Playground component unmounted');
};

  }, []);

  const handleMenuItemClick = (event) => {
    const code = event.target.value;
    const language = Object.keys(languageCodes).find(key => languageCodes[key] === code);
    console.log('MenuItem clicked:', language, code);
    setSelectedLanguage(code);
    setIsOpen(false);
};



  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    console.log('isOpen state changed:', isOpen);
  }, [isOpen]);

  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const initializeRecognition = useCallback(() => {
    if (!('webkitSpeechRecognition' in window)) {
      setError('Speech recognition is not supported in this browser.');
      return null;
    }
    const instance = new window.webkitSpeechRecognition();
    instance.continuous = true;
    instance.interimResults = false;
    instance.lang = 'en-IN';
    
    instance.onresult = (event) => {
      const last = event.results.length - 1;
      const text = event.results[last][0].transcript;
      setTranscript(text);
    };

    instance.onerror = (event) => {
      setError(`Speech recognition error: ${event.error}`);
      setIsSTTActive(false);
    };

    instance.onend = () => {
      console.log('Speech recognition ended');
      setIsSTTActive(false);
    };

    return instance;
  }, []);

  const convertToWav = async (blob) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    const wavBuffer = audioBufferToWav(audioBuffer);
    return new Blob([wavBuffer], { type: 'audio/wav' });
  };

  const audioBufferToWav = (buffer) => {
    const numChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;

    const wavDataBytes = buffer.length * blockAlign;
    const wavBytes = 44 + wavDataBytes;

    const arrayBuffer = new ArrayBuffer(wavBytes);
    const view = new DataView(arrayBuffer);

    // WAV header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + wavDataBytes, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * blockAlign, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, wavDataBytes, true);

    const channels = [];
    for (let i = 0; i < numChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numChannels; channel++) {
        const sample = channels[channel][i];
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }

    return arrayBuffer;
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const handleAiResponse = useCallback(async (audioBlob) => {
    if (!selectedLanguage) {
      setError('Please select a language before recording.');
      return;
    }

    try {
      const wavBlob = await convertToWav(audioBlob);
      const tempFile = new File([wavBlob], 'temp_audio.wav', { type: 'audio/wav' });

      const arrayBuffer = await wavBlob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      setUserAudioBuffer(audioBuffer);
      setUserAudio(wavBlob);

      const formData = new FormData();
      formData.append('file', tempFile);

      const apiUrl = `/api/translate_speech?target_lang=${selectedLanguage}`;
      console.log('Sending request to:', apiUrl);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        timeout: 30000,
      });

      if (response.data) {
        const audioBlob = new Blob([response.data], { type: 'audio/wav' });
        const audioId = Date.now().toString();
        
        // Save to IndexedDB
        if (db) {
          const transaction = db.transaction([storeName], "readwrite");
          const store = transaction.objectStore(storeName);
          const request = store.put({ id: audioId, blob: audioBlob });

          request.onsuccess = () => {
            setAiResponse(audioId);
            console.log('Audio response received and saved successfully');
            console.log('Saved audio ID:', audioId);
          };

          request.onerror = (event) => {
            console.error("Error saving to IndexedDB:", event.target.error);
            setError('Error saving audio response to IndexedDB.');
          };
        } else {
          console.error("IndexedDB not initialized");
          setError('IndexedDB not initialized. Unable to save audio response.');
        }
      } else {
        setError('Received an invalid response from the server.');
      }
    } catch (error) {
      console.error('Error in AI response:', error);
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_NETWORK') {
          setError(`Unable to connect to the server. Please check your internet connection and try again. Error details: ${error.message}`);
        } else if (error.response) {
          setError(`Server error: ${error.response.status} - ${error.response.statusText}. Error details: ${error.message}`);
        } else {
          setError(`An error occurred while processing your request. Please try again. Error details: ${error.message}`);
        }
      } else {
        setError(`Unexpected error: ${error.message}`);
      }
      console.error('Full error object:', error);
    }
  }, [selectedLanguage]);

  const startRecording = useCallback(() => {
    console.log('startRecording called');
    if (!selectedLanguage) {
      setError('Please select a language before recording.');
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          handleAiResponse(audioBlob);
        };

        mediaRecorder.start();
        console.log('MediaRecorder started');
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
        setError('Error accessing microphone. Please check your permissions.');
      });
  }, [handleAiResponse, selectedLanguage]);

  const stopRecording = useCallback(() => {
    console.log('stopRecording called');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      console.log('MediaRecorder stopped');
    }
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      console.log('Speech recognition stopped');
    }
  }, []);

  const toggleListening = useCallback(() => {
    console.log('toggleListening called, isSTTActive:', isSTTActive);
    if (isSTTActive) {
      console.log('Stopping listening');
      stopRecording();
      setIsSTTActive(false);
    } else {
      console.log('Starting listening');
      if (!selectedLanguage) {
        setError('Please select a language before starting.');
        return;
      }
      setIsSTTActive(true);
      setTranscript('');
      setAiResponse('');
      setError(null);

      if (!recognitionRef.current) {
        recognitionRef.current = initializeRecognition();
      }

      recognitionRef.current.start();
      startRecording();
    }
  }, [isSTTActive, initializeRecognition, startRecording, stopRecording, selectedLanguage]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'audio/wav' || file.type === 'audio/webm' || file.type === 'audio/mp3')) {
      setLocalFile(file);
      setError(null);
    } else {
      setError('Please upload a valid audio file (WAV, WebM, or MP3).');
    }
  };

  const handleLocalFileSubmit = async () => {
    if (!localFile) {
      setError('Please select an audio file first.');
      return;
    }

    if (!selectedLanguage) {
      setError('Please select a language before submitting.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', localFile);

      const apiUrl = `/api/translate_speech?target_lang=${selectedLanguage}`;
      console.log('Sending request to:', apiUrl);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
        timeout: 30000,
      });

      if (response.data) {
        const audioBlob = new Blob([response.data], { type: 'audio/wav' });
        const audioId = Date.now().toString();
        
        // Save to IndexedDB
        if (db) {
          const transaction = db.transaction([storeName], "readwrite");
          const store = transaction.objectStore(storeName);
          const request = store.put({ id: audioId, blob: audioBlob });

          request.onsuccess = () => {
            setAiResponse(audioId);
            console.log('Audio response received and saved successfully');
            console.log('Saved audio ID:', audioId);
          };

          request.onerror = (event) => {
            console.error("Error saving to IndexedDB:", event.target.error);
            setError('Error saving audio response to IndexedDB.');
          };
        } else {
          console.error("IndexedDB not initialized");
          setError('IndexedDB not initialized. Unable to save audio response.');
        }
      } else {
        setError('Received an invalid response from the server.');
      }
    } catch (error) {
      console.error('Error in AI response:', error);
      if (axios.isAxiosError(error)) {
        if (error.code === 'ERR_NETWORK') {
          setError('Unable to connect to the server. Please check your internet connection and try again.');
        } else if (error.response) {
          setError(`Server error: ${error.response.status} - ${error.response.statusText}`);
        } else {
          setError('An error occurred while processing your request. Please try again.');
        }
      } else {
        setError(`Unexpected error: ${error.message}`);
      }
    }
  };

  return (
    <Box className="playground-container" sx={{ padding: '20px', marginBottom: '60px' }}>
      <Typography variant="h4" className="playground-title">
        AI Talker Playground
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: 2, color: 'text.secondary' }}>
        This playground uses an external API for speech translation. Please ensure you have a stable internet connection.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ width: '250px', zIndex: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={selectedLanguage}
                label="Language"
                onChange={handleMenuItemClick}
                onClose={handleClose}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value="">
                  <em>Select a language</em>
                </MenuItem>
                {Object.entries(languageCodes).map(([language, code]) => (
                  <MenuItem 
                    key={code} 
                    value={code}
                  >
                    {language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Selected Language: {selectedLanguage ? Object.keys(languageCodes).find(key => languageCodes[key] === selectedLanguage) || selectedLanguage : 'None'}
            </Typography>
          </Box>
          <Box>
            <button
              onClick={toggleListening}
              style={{
                padding: '10px',
                fontSize: '16px',
                backgroundColor: isSTTActive ? '#d32f2f' : '#1976d2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {isSTTActive ? "Stop Listening" : "Start Listening"}
            </button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input
            type="file"
            accept=".wav,.webm,.mp3"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            id="audio-file-input"
          />
          <label htmlFor="audio-file-input">
            <Button variant="contained" component="span">
              Upload audio file
            </Button>
          </label>
          {localFile && (
            <Typography variant="body2">
              Selected file: {localFile.name}
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={handleLocalFileSubmit}
            disabled={!localFile || !selectedLanguage}
          >
            Submit audio file
          </Button>
        </Box>
        <Box className="playground-waves" sx={{ width: '100%', height: '100px', overflow: 'hidden' }}>
          <AnimatedWaves isSTTActive={isSTTActive} />
        </Box>
        <Box className="playground-content" sx={{ minHeight: '200px', overflowY: 'auto', maxHeight: '400px', width: '100%' }}>
          <Typography variant="body1" className="playground-text">
            You said: {transcript || '(Waiting for speech input...)'}
          </Typography>
          {userAudio && (
            <Box>
              <Typography variant="body1" className="playground-text">
                Your voice recording:
              </Typography>
              <IconButton onClick={() => playAudio(userAudioBuffer)}>
                <PlayArrowIcon />
              </IconButton>
            </Box>
          )}
          {aiResponse && (
            <Box>
              <Typography variant="body1" className="playground-text">
                AI response received. Click to play:
              </Typography>
              <IconButton onClick={() => {
                if (!db) {
                  console.error("Database not initialized");
                  setError('Database not initialized. Unable to play audio.');
                  return;
                }
                console.log('Attempting to retrieve audio with ID:', aiResponse);
                const transaction = db.transaction([storeName], "readonly");
                const store = transaction.objectStore(storeName);
                const request = store.get(aiResponse);

                request.onsuccess = (event) => {
                  const result = event.target.result;
                  console.log('Retrieved result:', result);
                  if (result && result.blob) {
                    const audioBlob = result.blob;
                    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    const fileReader = new FileReader();

                    fileReader.onload = function(event) {
                      audioContext.decodeAudioData(event.target.result, function(buffer) {
                        const source = audioContext.createBufferSource();
                        source.buffer = buffer;
                        source.connect(audioContext.destination);
                        source.start(0);
                      }, function(e) {
                        console.error('Error decoding audio data:', e);
                        setError('Error decoding audio data');
                      });
                    };

                    fileReader.onerror = function(error) {
                      console.error('Error reading file:', error);
                      setError('Error reading audio file');
                    };

                    fileReader.readAsArrayBuffer(audioBlob);
                  } else {
                    console.error("Audio blob not found in IndexedDB");
                    setError('Audio data not found in storage.');
                  }
                };

                request.onerror = (event) => {
                  console.error("Error retrieving audio:", event.target.error);
                  setError('Error retrieving audio from storage.');
                };
              }}>
                <PlayArrowIcon />
              </IconButton>
            </Box>
          )}
          {error && (
            <Typography variant="body1" className="playground-error" style={{ whiteSpace: 'pre-line' }}>
              Error: {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Playground;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const CheckIcon = ({ className }) => (
  <svg className={`w-5 h-5 ${className}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const PricingCard = ({ title, price, minutes, features, onSelect, isPremium, index }) => {
  const costPerMinute = (price / minutes).toFixed(2);

  return (
    <motion.div 
      className={`bg-white rounded-lg shadow-2xl overflow-hidden ${
        isPremium ? 'border-4 border-yellow-500 z-10' : ''
      }`}
      initial={{ opacity: 0, y: isPremium ? 100 : 20, scale: isPremium ? 1.05 : 1 }}
      animate={{ 
        opacity: 1, 
        y: 0, 
        scale: isPremium ? 1.05 : 1,
        boxShadow: isPremium ? '0 25px 50px -12px rgba(234, 179, 8, 0.25)' : 'none'
      }}
      transition={{ 
        type: "spring",
        stiffness: 100,
        damping: 20,
        delay: index * 0.1
      }}
      whileHover={{ 
        scale: isPremium ? 1.08 : 1.03,
        boxShadow: isPremium 
          ? '0 35px 60px -15px rgba(234, 179, 8, 0.3)' 
          : '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        transition: { 
          type: "spring", 
          stiffness: 300, 
          damping: 20 
        }
      }}
    >
      <motion.div 
        className={`p-8 ${isPremium ? 'bg-gradient-to-br from-yellow-100 via-yellow-200 to-yellow-300' : ''}`}
        whileHover={isPremium ? { 
          background: 'linear-gradient(135deg, #fef3c7 0%, #fde68a 50%, #fcd34d 100%)'
        } : {}}
      >
        <h2 className={`${isPremium ? 'text-3xl' : 'text-2xl'} font-extrabold mb-4 text-center ${isPremium ? 'text-yellow-800' : 'text-gray-800'}`}>
          {title}
        </h2>
        <div className="text-center">
          <p className={`${isPremium ? 'text-3xl' : 'text-4xl'} font-black mb-2 ${isPremium ? 'text-yellow-700' : 'text-gray-600'}`}>
            ₹{price}/{isPremium ? (
              <span className="text-4xl">{minutes}</span>
            ) : (
              <span className="text-2xl">{minutes}</span>
            )}
            <span className={`${isPremium ? 'text-2xl' : 'text-lg'} font-bold ml-1`}>minutes</span>
          </p>
          <p className={`mb-6 ${isPremium ? 'text-yellow-800 text-xl font-bold' : 'text-gray-600 text-lg font-semibold'}`}>
            ₹{title === 'Basic' ? '6.67' : costPerMinute}/minute
          </p>
        </div>
        <ul className="space-y-3 text-base">
          {features.map((feature, index) => (
            title === 'Basic' ? (
              <li key={index} className="flex items-center">
                <CheckIcon className="mr-3 text-green-500" />
                <span className="text-gray-600">{feature}</span>
              </li>
            ) : (
              <motion.li key={index} className="flex items-center" whileHover={{ x: 5 }}>
                <CheckIcon className={`mr-3 ${isPremium ? 'text-yellow-600' : 'text-green-500'}`} />
                <span className={`${isPremium ? 'text-yellow-900 font-semibold' : 'text-gray-600'}`}>{feature}</span>
              </motion.li>
            )
          ))}
        </ul>
      </motion.div>
      <div className={`p-6 ${isPremium ? 'bg-gradient-to-r from-yellow-400 to-yellow-500' : 'bg-gray-100'}`}>
        <motion.button 
          className={`w-full py-4 px-6 rounded-full font-extrabold ${isPremium ? 'text-xl' : 'text-lg'} ${
            isPremium 
              ? 'bg-yellow-600 text-white hover:bg-yellow-700' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
          onClick={() => onSelect(title.toLowerCase(), price)}
          whileHover={{ scale: 1.05, boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)' }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          {isPremium ? 'Select Premium Plan' : 'select Plan'}
        </motion.button>
      </div>
    </motion.div>
  );
};

const Pricing = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (plan, price) => {
    navigate('/login', { state: { selectedPlan: plan, selectedPrice: price } });
  };

  const features = [
    'Multilingual Call Handling Agent',
    'Self-Learning and Continuous Improvement',
    'Voice Customization',
    'Personality Customization',
    'Multiple Use Case Options',
    'Advance Actionable Agent',
    'Automated Appointment Scheduling',
    'Advanced Call Routing',
    'Sentiment Analysis',
    'Proactive Engagement',
    'Advanced AI Voice Support',
    'High Voice Recognition Accuracy',
    'Advanced Analytics and Reporting',
    'Comprehensive Logs and Monitoring',
    'Conversation Transcript',
    'Conversation Audio Recordings',
    'Email and Call Support',
    'Secure and Compliant'
  ];

  const plans = [
    {
      title: 'Basic',
      price: 9999,
      minutes: 1500,
      features: features
    },
    {
      title: 'Standard',
      price: 14999,
      minutes: 2500,
      features: features
    },
    {
      title: 'Premium',
      price: 29999,
      minutes: 6000,
      features: features,
      isPremium: true
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 font-sans bg-gray-50">
      <motion.h1 
        className="text-4xl md:text-6xl font-bold pb-10 pt-20 text-center text-transparent bg-clip-text bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        Pick a Plan
      </motion.h1>
      <motion.p 
        className="text-2xl text-center mb-16 text-gray-600 font-semibold"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2, type: 'spring', stiffness: 100 }}
      >
        Choose the perfect plan for your business needs
      </motion.p>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
        {plans.map((plan, index) => (
          <PricingCard
            key={plan.title}
            {...plan}
            onSelect={handleSelectPlan}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Pricing;

import React from 'react';
import BlogCard from './BlogCard/BlogCard';
import Pagination from './Pagination';
import { motion } from 'framer-motion';
import './BlogListPage.css';

const BlogListPage = ({ blogs, currentPage, handlePageChange, totalPages }) => {
  if (!blogs || blogs.length === 0) {
    return <div>No blogs found</div>;
  }

  return (
    <div className="blog-list-container">
      <div className="blog-list-content">
        <motion.h1 
          className="text-4xl md:text-6xl font-bold pb-10 pt-20 text-center text-transparent bg-clip-text bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          Our Blogs
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <div className="container mx-auto px-4 sm:px-6">
            {/* Grid Blogs Section */}
            <motion.div 
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.9 }}
            >
              {blogs.map((blog, index) => (
                <motion.div
                  key={blog.postId}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 * (index + 1) }}
                >
                  <BlogCard
                    postId={blog.postId}
                    title={blog.title}
                    date={blog.createdAt}
                    image={blog?.images && blog?.images[0] ? blog?.images[0] : '/path-to-default-image.jpg'}
                  />
                </motion.div>
              ))}
            </motion.div>
          </div>
        </motion.div>

        <div className="mt-8 sm:mt-12 mb-16">
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogListPage;

import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { logoBlue } from "../../assets";

function Header() {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? "shadow-lg backdrop-blur-sm" : "bg-transparent"}`}>
      <nav className="container mx-auto px-3 py-3 flex justify-between items-center">
        <NavLink to="/" className="flex items-center">
          <img src={logoBlue} alt="Verbalyze Logo" className="h-12 w-auto" />
        </NavLink>

        <div className="hidden md:flex items-center space-x-6">
          <NavItem to="/">Home</NavItem>
          <NavItem to="/about-us">About Us</NavItem>
          <NavItem to="/pricing">Pricing</NavItem>
          <NavItem to="/blog">Blog</NavItem>
          <NavItem to="/login" isButton>Login</NavItem>
        </div>

        <div className="md:hidden">
          <button 
            className="navbar-burger flex items-center text-blue-600 p-3" 
            onClick={(e) => { e.stopPropagation(); setIsMenuOpen(!isMenuOpen); }}
          >
            <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
      </nav>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logoBlue} alt="Verbalyze" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <NavItem to="/" mobile onClick={() => handleNavigation('/')}>Home</NavItem>
                    <NavItem to="/about-us" mobile onClick={() => handleNavigation('/about-us')}>About Us</NavItem>
                    <NavItem to="/pricing" mobile onClick={() => handleNavigation('/pricing')}>Pricing</NavItem>
                    <NavItem to="/blog" mobile onClick={() => handleNavigation('/blog')}>Blog</NavItem>
                    <NavItem to="/login" mobile isButton onClick={() => handleNavigation('/login')}>Login</NavItem>
                  </nav>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}

function NavItem({ to, children, isButton, mobile, onClick }) {
  const baseClasses = "text-base font-medium text-gray-600 hover:text-blue-800";
  const buttonClasses = "ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 rounded-md shadow-sm text-base font-medium text-white bg-[rgb(11,88,181)] hover:bg-blue-900";
  
  let className = isButton ? buttonClasses : baseClasses;
  if (mobile) {
    className = isButton 
      ? "w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
      : "text-base font-medium text-gray-900 hover:text-gray-700";
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) => 
        `${className} hover:no-underline`
      }
      style={({ isActive }) => 
        isActive && !isButton ? { fontWeight: 'bold', color: 'rgb(11,88,181)' } : {}
      }
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
}


export default Header;

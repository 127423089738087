import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './BlogDetailPage.css';
import BlogCard from './BlogCard/BlogCard';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const BlogDetailPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { postId } = useParams();
  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [blogResponse, allBlogsResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/blog/${postId}`),
          axios.get(`${apiUrl}/api/blog`)
        ]);

        console.log("Blog response:", blogResponse.data);
        console.log("All blogs response:", allBlogsResponse.data);

        if (blogResponse.data) {
          setBlog(blogResponse.data);
        } else {
          throw new Error('Blog data not found in the response');
        }

        if (allBlogsResponse.data && Array.isArray(allBlogsResponse.data.posts)) {
          const allBlogs = allBlogsResponse.data.posts;
          const relatedBlogs = allBlogs.filter(blog => blog.postId !== postId);
          setRelatedBlogs(relatedBlogs);
          console.log("Related blogs:", relatedBlogs);
        } else {
          console.error('Blogs data not found in the response or is not an array');
          setRelatedBlogs([]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(`Failed to load blog details or related blogs: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [postId, apiUrl]);

  if (loading) {
    return (
      <div className="blog-detail-loading">
        <div className="blog-detail-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="blog-detail-error">{error}</div>;
  }

  if (!blog) {
    return <div className="blog-detail-error">Blog not found!</div>;
  }

  return (
    <div className="blog-detail-container">
      <div className="blog-detail-main-content">
        <Link to="/blog" className="blog-detail-back-link" onClick={(e) => e.stopPropagation()}>
          <button className="blog-detail-back-button">
            <svg xmlns="http://www.w3.org/2000/svg" className="blog-detail-back-icon" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
            Back to Blogs
          </button>
        </Link>

        {/* Blog Image */}
        {blog.images && blog.images[0] && (
          <img 
            src={blog.images[0]} 
            alt={blog.title} 
            className="blog-detail-image"
          />
        )}

        {/* Blog Title */}
        <h1 className="blog-detail-title">{blog.title}</h1>

        {/* Author and Date */}
        <div className="blog-detail-meta">
          <p className="blog-detail-author">{blog.author}</p>
          <p className="blog-detail-date">Published on {formatDate(blog.createdAt)}</p>
        </div>

        {/* Blog Description (if available) */}
        {blog.description && (
          <div className="blog-detail-description">
            {blog.description}
          </div>
        )}

        {/* Blog Content */}
        <div
          className="blog-detail-content"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />

        {/* Tags */}
        {blog.tags && blog.tags.length > 0 && (
          <div className="blog-detail-tags">
            <h3>Tags:</h3>
            <div className="blog-detail-tags-list">
              {blog.tags.map((tag, index) => (
                <span key={index} className="blog-detail-tag">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        )}

        {/* Back to Top Button */}
        <div className="blog-detail-back-to-top">
          <button
            onClick={scrollToTop}
            className="blog-detail-back-to-top-button"
            aria-label="Back to top"
          >
            Back to Top
          </button>
        </div>
      </div>

      {/* Share section and Related Blog Cards */}
      {!loading && !error && (
        <div className="blog-detail-sidebar">
          <div className="blog-detail-share">
            <h2 className="blog-detail-share-title">SHARE</h2>
            <div className="blog-detail-share-icons">
              <a
                href={`mailto:?subject=${encodeURIComponent(blog.title)}&body=${encodeURIComponent(`Check out this blog post: ${window.location.href}`)}`}
                className="blog-detail-share-icon email"
                aria-label="Share via Email"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/></svg>
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="blog-detail-share-icon linkedin"
                aria-label="Share on LinkedIn"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="blog-detail-share-icon facebook"
                aria-label="Share on Facebook"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(blog.title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="blog-detail-share-icon twitter"
                aria-label="Share on X (Twitter)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
              </a>
              <a
                href={`https://wa.me/?text=${encodeURIComponent(`Check out this blog post: ${blog.title} ${window.location.href}`)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="blog-detail-share-icon whatsapp"
                aria-label="Share on WhatsApp"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
              </a>
            </div>
          </div>
          <h2 className="blog-detail-related-title">OTHER POSTS</h2>
          {relatedBlogs && relatedBlogs.length > 0 ? (
            <div className="blog-detail-related-cards">
              {relatedBlogs.slice(0, 3).map((relatedBlog) => (
                <BlogCard
                  key={relatedBlog.postId}
                  postId={relatedBlog.postId}
                  title={relatedBlog.title}
                  date={relatedBlog.createdAt}
                  image={relatedBlog.images && relatedBlog.images.length > 0 ? relatedBlog.images[0] : undefined}
                  className="blog-detail-card"
                />
              ))}
            </div>
          ) : (
            <p>No other posts found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogDetailPage;

import React, { useRef, useEffect, useCallback } from 'react';
import './animatedwaves.css';

const AnimatedWaves = ({ isSTTActive, isTTSPlaying, colorMode = 'light', isRedButtonClicked = false }) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const speedRef = useRef(0.0002);

  const updateSpeed = useCallback(() => {
    const baseSpeedFactor = isSTTActive ? 0.0005 : 0.0002;
    const newSpeed = isTTSPlaying ? baseSpeedFactor * 3 : baseSpeedFactor;
    speedRef.current = newSpeed;
    console.log('Speed updated:', newSpeed, 'TTS playing:', isTTSPlaying);
  }, [isSTTActive, isTTSPlaying]);

  useEffect(() => {
    updateSpeed();
  }, [updateSpeed]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const createGradient = () => {
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      if (colorMode === 'dark') {
        gradient.addColorStop(0, "rgba(0, 191, 255, 0.2)");    // DeepSkyBlue with opacity
        gradient.addColorStop(0.5, "rgba(30, 144, 255, 0.7)"); // DodgerBlue with higher opacity
        gradient.addColorStop(1, "rgba(0, 191, 255, 0.2)");    // DeepSkyBlue with opacity
      } else {
        gradient.addColorStop(0, "rgba(65, 105, 225, 0.2)");   // RoyalBlue with opacity
        gradient.addColorStop(0.5, "rgba(0, 0, 255, 0.7)");    // Blue with higher opacity
        gradient.addColorStop(1, "rgba(65, 105, 225, 0.2)");   // RoyalBlue with opacity
      }
      return gradient;
    };

    const drawSine = (time, options) => {
      const {
        amplitude,
        wavelength,
        segmentLength = 10,
        lineWidth,
      } = options;

      const yAxis = canvas.height / 2;
      const waveWidth = canvas.width * 0.95;
      const waveLeft = canvas.width * 0.025;

      ctx.beginPath();
      ctx.moveTo(0, yAxis);
      ctx.lineTo(waveLeft, yAxis);

      for (let i = 0; i < waveWidth; i += segmentLength) {
        const x = (time * 8) + (-yAxis + i) / wavelength;
        const y = Math.sin(x);
        const amp = ((Math.sin((i / waveWidth) * Math.PI * 2 - Math.PI / 2) + 1) * 0.5) * amplitude;
        ctx.lineTo(i + waveLeft, amp * y + yAxis);
      }

      ctx.lineTo(canvas.width, yAxis);
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    };

    const animate = (currentTime) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      const gradient = createGradient();
      ctx.strokeStyle = gradient;

      const amplitudeFactor = isSTTActive ? (isTTSPlaying ? 1.0 : 1) : 0.3;
      const wavelengthFactor = isTTSPlaying ? 0.75 : 1;

      const time = currentTime * speedRef.current;

      drawSine(time, { 
        amplitude: 150 * amplitudeFactor, 
        wavelength: 200 * wavelengthFactor, 
        lineWidth: 3 
      });
      drawSine(time, { 
        amplitude: 150 * amplitudeFactor, 
        wavelength: 100 * wavelengthFactor, 
        lineWidth: 2 
      });
      drawSine(time, { 
        amplitude: -150 * amplitudeFactor, 
        wavelength: 50 * wavelengthFactor, 
        lineWidth: 1, 
        segmentLength: 10 
      });
      drawSine(time, { 
        amplitude: -100 * amplitudeFactor, 
        wavelength: 100 * wavelengthFactor, 
        lineWidth: 0.5, 
        segmentLength: 10 
      });

      animationRef.current = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animate(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [colorMode, isSTTActive, isTTSPlaying, isRedButtonClicked]);

  return (
    <div className={`wave-container ${isSTTActive ? 'stt-active' : ''} ${isTTSPlaying ? 'tts-playing' : ''}`}>
      <canvas ref={canvasRef} id="waves"></canvas>
    </div>
  );
};

export default AnimatedWaves;
